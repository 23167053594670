import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Paper, CircularProgress, Stack, IconButton, Typography, MenuItem, Menu, Divider, TextField } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import { autorun } from 'mobx';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import { useSearchParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Iconify } from '~/components/iconify';
import { observer } from 'mobx-react-lite';
import type { IChat } from '~/mst/models/chat';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';

import Message from './message';
import TextDelta from './text_delta';
import * as styled from './styled';

type ChatType = {
  model: IChat;
};

const ICON = {
  mr: 2,
  width: 20,
  height: 20
};

function Chat({ model }: ChatType) {
  const [messageText, setMessageText] = useState('');
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const { t } = useLocales();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editName, setEditName] = useState(false);
  const open = Boolean(anchorEl);
  const [, setSearchParams] = useSearchParams();

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  useEffect(
    () =>
      autorun(() => {
        if (!model?.thread?.messages?.isFetching && !model?.thread?.messages?.isFetched) {
          model?.thread?.messages.fetch();
        }
      }),
    [model?.thread?.messages]
  );

  useEffect(
    () =>
      autorun(() => {
        if (messagesEndRef.current && (model?.sortedMessages.length || model?.text_delta != null)) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }),
    [model?.sortedMessages, model?.text_delta]
  );

  const handleSendMessage = useCallback(async () => {
    if (messageText.trim()) {
      setMessageText('');
      await model?.createMessage(messageText.trim());
    }
  }, [messageText, model]);

  const handleEditName = useCallback(() => setEditName((value) => !value), []);

  const handleDelete = useCallback(async () => {
    try {
      handleClose();
      await model?.destroy();
      setSearchParams({});
      toasts.success(t('notifications.success.chat_assistant_deleted'));
    } catch (e) {
      toasts.error(t('notifications.errors.server_error'));
    }
  }, [model, t, handleClose, setSearchParams]);

  const handleCreateNewThread = useCallback(async () => {
    try {
      handleClose();
      await model.createThread();
      setSearchParams({ assistant: model.assistant_id!, thread: model.thread_id! });
    } catch (e) {
      toasts.error(t('notifications.errors.server_error'));
    }
  }, [t, handleClose, setSearchParams, model]);

  return (
    <Box sx={styled.chatContainer}>
      <Stack direction="row" justifyContent="space-between" spacing={1} mb={1}>
        <Typography variant="subtitle1" className="thread-name-conatainer" gutterBottom textAlign="center" sx={styled.header}>
          <IconButton
            size="small"
            onClick={handleEditName}
            sx={{ ...(editName && { visibility: 'hidden !important' }) }}
            className="thread-name-button"
          >
            <EditIcon fontSize="1em" />
          </IconButton>
          {editName ? (
            <TextField
              defaultValue={model?.thread?.name}
              fullWidth
              sx={{ ...(editName && { marginLeft: '-8px !important' }) }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  model.thread.setName(e.target.value);
                  setEditName(false);
                }
              }}
              onBlur={() => {
                setEditName(false);
              }}
              inputRef={(input) => input && input.focus()}
            />
          ) : (
            model?.thread?.name
          )}
        </Typography>
        <IconButton onClick={handleOpen}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          sx={{
            zIndex: 999999
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1,
              '& .MuiSvgIcon-root': {
                marginRight: 2
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)'
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleCreateNewThread}>
            <EditNoteIcon sx={ICON} />
            New Thread
          </MenuItem>

          <Divider />
          <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
            <DeleteIcon sx={ICON} />
            Delete Assistant
          </MenuItem>
        </Menu>
      </Stack>
      <Box sx={styled.mainContent}>
        <Box sx={styled.chatBox}>
          <Paper sx={styled.messagePaper} elevation={3}>
            <>
              {model?.sortedMessages.map((message) => <Message key={message.id} message={message} />)}
              {model?.thinking ? (
                <Stack direction="row" justifyContent="center">
                  <CircularProgress size={25} color="info" />
                </Stack>
              ) : (
                <TextDelta model={model} />
              )}
              <div ref={messagesEndRef} />
            </>
          </Paper>

          <Box sx={styled.inputContainer}>
            <TextareaAutosize
              minRows={3}
              placeholder="Type your message..."
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <Box sx={styled.sendButton}>
              <IconButton color="primary" variant="contained" onClick={handleSendMessage} disabled={model?.thinking || !messageText.trim()}>
                <Iconify icon="eva:arrow-circle-up-fill" width={40} height={40} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default observer(Chat);
