import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { CardContent } from '@mui/material';
import { useFormState, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import { CONDITION_MAX_PERIOD_MINUTES } from '~/utils/constants';
import I18n from '~/utils/i18n';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';

import DevicesSelect from '~/components/devices_select';
import Select from '~/components/final_form/select';
import FormGrid from '~/components/@material-extend/form_grid';
import TextInput from '~/components/final_form/text_input';
import { ModalActions } from '~/components/modal';
import type { INode } from '~/mst/models/node';

type FormProps = {
  handleCancel: VoidFunction;
  handleSubmit: VoidFunction;
  node: INode;
  deviceId?: string;
};

const funcOptions = [
  { label: I18n.t('conditions.expressions.avg_title'), value: 'avg' },
  { label: I18n.t('conditions.expressions.diff_title'), value: 'diff' },
  { label: I18n.t('conditions.expressions.max_title'), value: 'max' },
  { label: I18n.t('conditions.expressions.min_title'), value: 'min' }
];

const periodOptions = [
  { label: I18n.t('time.number_mins', { n: 5 }), value: 5 },
  { label: I18n.t('time.number_mins', { n: 10 }), value: 10 },
  { label: I18n.t('time.number_mins', { n: 15 }), value: 15 },
  { label: I18n.t('time.number_mins', { n: 30 }), value: 30 },
  { label: I18n.t('time.number_mins', { n: 45 }), value: 45 },
  { label: I18n.t('time.one_hour'), value: 60 },
  { label: I18n.t('time.number_hours', { n: 2 }), value: 2 * 60 },
  { label: I18n.t('time.number_hours', { n: 6 }), value: 6 * 60 },
  { label: I18n.t('time.number_hours', { n: 8 }), value: 8 * 60 },
  { label: I18n.t('time.number_hours', { n: 12 }), value: 12 * 60 },
  { label: I18n.t('time.one_day'), value: 24 * 60 },
  { label: I18n.t('time.number_days', { n: 7 }), value: 7 * 24 * 60 },
  { label: I18n.t('time.number_days', { n: 30 }), value: 30 * 24 * 60 }
];

export function AddStatisticalParameterForm({ handleCancel, handleSubmit, node, deviceId }: FormProps) {
  const { t } = useLocales();
  const { nodes } = useMst();

  const { change } = useForm();

  const {
    values: { data_point_id: dataPointId, func, period, node_id2: nodeId2, data_point_id2: dataPointId2 },
    hasValidationErrors
  } = useFormState({ subscription: { values: true, hasValidationErrors: true } });

  useEffect(() => {
    if (func === 'diff' && dataPointId && dataPointId2) {
      change(
        'name',
        `[${node.serial}] ${node?.getDataPointById(dataPointId).presentName} - [${nodes.getById(nodeId2).serial}] ${
          nodes.getById(nodeId2).getDataPointById(dataPointId2)?.presentName
        }`
      );
    }
    if (dataPointId && func && period) {
      const name = `${node?.getDataPointById(dataPointId).presentName} ${func.toUpperCase()} ${
        periodOptions.find(({ value }) => value === period)?.label
      }`;
      change('name', name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPointId, func, period, dataPointId2]);

  const isDiff = func === 'diff';

  const parametersOptions = useMemo(() => {
    const options = node?.defaultParamValueLabelPairs;
    if (deviceId) {
      return options?.filter(({ model }) => {
        if (model.isMetric) {
          return model.linked[0]?.deviceId === deviceId;
        }
        return model.deviceId === deviceId;
      });
    }
    return options || [];
  }, [deviceId, node]);

  return (
    <>
      <CardContent>
        <FormGrid>
          <Select name="func" label="Function" options={funcOptions} />
          <Select searchable name="data_point_id" label={`${I18n.t('models.parameter')}${isDiff ? ' 1' : ''}`} options={parametersOptions} />
          {isDiff && (
            <>
              <DevicesSelect name="node_id2" label={I18n.t('models.device')} options={nodes?.valueLabelPairs || []} />
              <Select
                searchable
                name="data_point_id2"
                loading={nodes.getById(nodeId2)?.isFetching}
                label={`${I18n.t('models.parameter')} 2`}
                options={nodes.getById(nodeId2)?.dataPointsValueLabelPairs || []}
              />
            </>
          )}

          {!isDiff && <Select name="period" label="Period" options={periodOptions} />}
          <TextInput name="name" label="Name" />
        </FormGrid>
        <OnChange name="node_id2">
          {(value) => {
            if (value) {
              nodes.getById(value).fetch({ last: 1 });
            }
          }}
        </OnChange>
        <OnChange name="period">
          {(value) => {
            if (value >= CONDITION_MAX_PERIOD_MINUTES) {
              change('step', 60);
            } else {
              change('step', null);
            }
          }}
        </OnChange>
      </CardContent>
      <ModalActions onSave={handleSubmit} onClose={handleCancel} saveLabel={t('base.buttons.create')} disabled={hasValidationErrors} />
    </>
  );
}

export default observer(AddStatisticalParameterForm);
