/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import { getStore } from '~/mst/utils';
import { createFile, createJson } from '~/mst/models/gpt/assistants/actions';
import { IChatsModel } from './model';

export default (self: IChatsModel) => ({
  create: flow(function* create(model, params: { data: string; parameters: string[]; devices: string[]; name: string; metadata: any }) {
    try {
      model.startSyncing();
      const { data, name, metadata } = params;
      const file = yield createFile(createJson(data));
      model.progress = 'Creating NoesisGPT AI assistant...';
      const assistant = yield getStore(self).gpt.assistants.create(file, {
        name,
        metadata,
        instructions:
          `Your are an expert in analysing environmental monitoring data. You provide summary focusing on any trends, peaks, or concerns.\n` +
          `Highlight any implications for air quality, environmental impact, or health risks.\n` +
          `You provide insights on abnormal readings if present, suggest possible reasons and solutions.\n` +
          `The data is provided in json format and represented as a list of Timestamps, Devices. Each Device has a Name and Data fields.\n` +
          `Skip intermediate responses and code_interpreter evaluations, respond only with one final message.\n\n` +
          `All sensors are properly calibrated and report precise data.\n\n` +
          `List of Parameters:\n${params.parameters.join('\n')}\n\n` +
          `List of Devices:\n${params.devices.join('\n')}`
      });
      const thread = yield getStore(self).gpt.threads.create();
      model.name = name;
      model.progress = null;
      model.assistant_id = assistant?.id;
      model.thread_id = thread?.id;
      model.finishSyncing();
    } catch (e) {
      model.failSyncing();
    }
  })
});
