/* eslint-disable @typescript-eslint/naming-convention */
import { getStore } from '~/mst/utils';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import { IChatModel } from './model';

export default (self: IChatModel) => ({
  get assistant() {
    return getStore(self).gpt.assistants.getById(self.assistant_id);
  },
  get thread() {
    return getStore(self).gpt.threads.getById(self.thread_id);
  },
  get isNew() {
    return isNil(self.assistant_id) || isNil(self.thread_id);
  },
  get sortedMessages() {
    return orderBy(
      self.thread?.messages?.toArray.filter((message) => message.text),
      'created_at',
      'asc'
    );
  }
});
