import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';
import actions from './actions';

const Thread = model.views(views).actions(actions);

export interface IThread extends Instance<typeof Thread> {}

export default Thread;
