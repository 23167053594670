/* eslint-disable no-prototype-builtins */
import { useCallback, useState } from 'react';
import { Box, Stack, IconButton, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import get from 'lodash/get';
import { Icon } from '@iconify/react';
import plus from '@iconify/icons-eva/plus-outline';
import { ISensor } from '~/mst/models/device/sensor';
import { BodyModule } from '~/pages/device_edit/components';
import Select from '~/components/final_form/select';
import FormGrid from '~/components/@material-extend/form_grid';
import useLocales from '~/hooks/use_locales';
import useModal from '~/hooks/use_modal';

import SwitchFields from '../sensor/common/switch_fields';
import { SensorProperty } from '../components';
import ConfigureMeasurementModal from './configure_measurement_modal';

type SensorType = {
  sensor: ISensor;
};

const ChannelItem = observer(({ id, sensor, handleClick }) => {
  const { t } = useLocales();
  const isHasChannel = sensor.getDataPointByChannelId(+id) && get(sensor.channels, id);
  return (
    <Stack direction="row">
      <Select name={`channels.${id}`} options={sensor.channelsValueLabelPairs || []} label={t('device_monitor.channel', { id })} />
      {isHasChannel && (
        <Tooltip title={t('sensor.data_point.add_scale_title_wo_channel')}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton sx={{ width: 40, height: 40 }} onClick={() => handleClick(id)} size="small">
              <Icon icon={plus} />
            </IconButton>
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
});

function Adam({ sensor }: SensorType) {
  const { t } = useLocales();
  const { open, handleClose, handleOpen } = useModal();
  const [channelId, setChannelId] = useState(null);

  const handleChannelItemClick = useCallback(
    (id) => {
      setChannelId(id);
      handleOpen();
    },
    [setChannelId, handleOpen]
  );

  return (
    <BodyModule title={t('device_monitor.forms.settings')} columns={1} expandable expanded>
      <SwitchFields />
      <Box sx={{ mt: 2 }}>
        <SensorProperty name="channels">
          <FormGrid columns="2">
            {Object.entries(sensor.configuration?.channels || {})?.map(([key]) => (
              <ChannelItem sensor={sensor} id={key} key={key} handleClick={handleChannelItemClick} />
            ))}
          </FormGrid>
        </SensorProperty>
      </Box>
      <ConfigureMeasurementModal open={open} onClose={handleClose} sensor={sensor} channelId={channelId} />
    </BodyModule>
  );
}

export default observer(Adam);
