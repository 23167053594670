import { Stack } from '@mui/material';
import { MuiMarkdown } from 'mui-markdown';
import { observer } from 'mobx-react-lite';
import type { IChat } from '~/mst/models/chat';
import { MessageStyled } from './message';

type TextDeltaType = {
  model: IChat;
};

function TextDelta({ model }: TextDeltaType) {
  if (model?.text_delta == null) {
    return null;
  }
  return (
    <Stack direction="row" justifyContent="flex-start">
      <MessageStyled $role="system">
        <MuiMarkdown>{model?.text_delta}</MuiMarkdown>
      </MessageStyled>
    </Stack>
  );
}

export default observer(TextDelta);
