import React, { useMemo, useCallback, useState } from 'react';
import { Stack } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ButtonDesktopOnly from '~/components/adaptive_button';

import Breadcrumbs from '~/components/breadcrumbs';
import LoadingBox from '~/components/loading_box';
import Container from '~/components/container';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import ReportContent from './content';
import Actions from './actions';
import GptContainer from './gpt_container';

function ReportView() {
  const { id } = useParams();
  const { t } = useLocales();
  const [searchParams, setSearchParams] = useSearchParams();
  const { reports, auth, chats } = useMst();
  const { isFetching } = useFetch(reports);
  const [isVisible, setIsVisible] = useState(false);
  const isGtpVisible = isVisible || (searchParams.get('thread') && searchParams.get('assistant'));
  const report = reports.getById(id);

  const links = useMemo(
    () => [
      { title: t('reports.title'), to: 'reports' },
      [
        { title: t('reports.edit_details_breadcrumb'), to: `reports/${id}` },
        { title: t('reports.view_details_breadcrumb'), to: `reports/${id}/view` }
      ]
    ],
    [id, t]
  );

  const handleGptShow = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const chat = chats.getById(report?.chatId);
      setIsVisible(true);
      if (chat && chat.isNew === false) {
        setSearchParams({ assistant: chat.assistant_id, thread: chat.thread_id });
      }
    },
    [chats, report?.chatId, setSearchParams]
  );

  const handleGptHide = useCallback(() => {
    setIsVisible(false);
    setSearchParams({});
  }, [setSearchParams]);

  return (
    <Container>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
        <Breadcrumbs links={links} />
        <Stack direction="row" gap={1}>
          {(auth.user.isSupport || auth.user.isAethair) && (
            <ButtonDesktopOnly
              sx={{ height: '36px' }}
              aria-haspopup="true"
              variant="outlined"
              size="medium"
              disableElevation
              onClick={handleGptShow}
              startIcon={<AutoAwesomeIcon />}
              fullWidth
              breakpoint="sm"
            >
              NoesisGPT
            </ButtonDesktopOnly>
          )}
          <Actions token={report?.external_id} />
        </Stack>
      </Stack>
      <GptContainer isVisible={isGtpVisible} handleClose={handleGptHide} reportId={id} />
      {isFetching ? <LoadingBox /> : <ReportContent token={report?.external_id} name={report?.name} />}
    </Container>
  );
}

export default observer(ReportView);
