import { Box, Stack, IconButton } from '@mui/material';
import { Iconify } from '~/components/iconify';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { MuiMarkdown } from 'mui-markdown';
import { transientOptions } from '~/utils/styled';
import type { IMessage } from '~/mst/models/gpt/thread/message';
import CopyToClipboard from '~/components/copy_to_clipboard';

type MessageProps = {
  $role: string;
};

export const MessageStyled = styled(
  Box,
  transientOptions
)<MessageProps>(({ theme, $role }) => ({
  margin: '8px 0',
  padding: '16px',
  borderRadius: '8px',
  position: 'relative',
  backgroundColor: $role === 'user' ? '#e0f7fa' : '#fff',
  ...($role !== 'user' && { marginBottom: 0, paddingBottom: 0 }),
  display: 'inline-block',
  '.mardown-container': {
    'h3, h4, h5, h6': { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
    'p, ul, ol': { marginBottom: theme.spacing(1) },
    li: { margin: theme.spacing(0.5) },
    '> div > :last-child': { marginBottom: 0 }
  },
  '.footer-actions': { visibility: 'hidden' },
  '&:hover': {
    '.footer-actions': { visibility: 'visible' }
  }
}));

function Message({ message }: { message: IMessage }) {
  const { text, role } = message;

  return (
    <Stack direction="row" justifyContent={role === 'user' ? 'flex-end' : 'flex-start'}>
      <MessageStyled $role={role}>
        <Box className="mardown-container">
          <MuiMarkdown>{text}</MuiMarkdown>
        </Box>
        {role !== 'user' && (
          <Stack className="footer-actions" direction="row" gap={1} justifyContent="flex-start">
            <CopyToClipboard value={text}>
              <IconButton color="info">
                <Iconify icon="eva:copy-outline" />
              </IconButton>
            </CopyToClipboard>
          </Stack>
        )}
      </MessageStyled>
    </Stack>
  );
}

export default observer(Message);
