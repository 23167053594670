import { Box, styled, BoxProps, Stack, SxProps, Theme } from '@mui/material';
import { transientOptions } from '~/utils/styled';

export const chatContainer: SxProps<Theme> = {
  height: '100vh',
  padding: '16px',
  '.thread-name-conatainer': {
    width: '100%',
    '.MuiInputBase-root fieldset': {
      borderWidth: '1px'
    },
    '.MuiInputBase-input': {
      padding: '8px',
      width: '100%',
      fontSize: '1rem',
      fontWeight: 600
    },
    '.thread-name-button': {
      visibility: 'hidden',
      marginRight: '4px'
    },
    '&:hover': {
      '.thread-name-button': {
        visibility: 'visible'
      }
    }
  }
};

export const header: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center'
};

export const mainContent: SxProps<Theme> = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};

export const chatBox: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '85vh',
  width: '100%',
  padding: '16px',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
};

export const messagePaper: SxProps<Theme> = {
  border: 0,
  boxShadow: 'none',
  flexGrow: 1,
  overflowY: 'auto',
  padding: '16px',
  marginBottom: '16px'
};

export const inputContainer: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  textarea: {
    padding: '16px',
    width: '100%',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    color: '#333',
    lineHeight: '24px',
    borderColor: '#dfdfdf',
    borderRadius: '8px'
  }
};

export const sendButton: SxProps<Theme> = {
  position: 'absolute',
  right: 5,
  bottom: 5
};

export const deleteButton: SxProps<Theme> = {
  position: 'absolute',
  bottom: 20
};

interface IPanelType extends BoxProps {
  $isVisible: boolean;
}

const PanelStyled = styled(
  Box,
  transientOptions
)<IPanelType>(({ $isVisible, theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: '-50%',
  width: '50%',
  background: theme.palette.background.paper,
  zIndex: 9999,
  cursor: 'default',
  transition: 'right .2s',
  backgroundColor: '#f4f4f4',
  boxShadow: theme.palette.themeShadows.filterPanel,
  ...($isVisible && {
    right: 0
  }),
  ...(!$isVisible && {
    boxShadow: 'none'
  })
}));

export const PanelInnerStyled = styled(Stack)(() => ({
  position: 'relative'
}));

export default PanelStyled;
