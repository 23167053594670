/* eslint-disable no-param-reassign */
import { flow, applySnapshot, getIdentifier, getParent } from 'mobx-state-tree';
import openai from '~/services/openai';
import Message from '~/mst/models/gpt/thread/message';
import { IMessagesModel } from './model';

export default (self: IMessagesModel) => ({
  fetch: flow(function* fetch() {
    try {
      self.startFetching();
      const thread = getParent(self);
      const { data } = yield openai.beta.threads.messages.list(getIdentifier(thread), { limit: 100, order: 'asc' });
      applySnapshot(self.models, data);
      self.finishFetching();
    } catch (e) {
      self.failFetching(e);
    }
  }),
  create: flow(function* create(content) {
    const thread = getParent(self);
    const message = yield openai.beta.threads.messages.create(getIdentifier(thread), { role: 'user', content });
    self.add(Message.create(message));
  })
});
