/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import { TextareaAutosize } from '@mui/base';
import { Field as FinalField, FieldMetaState } from 'react-final-form';
import { SxProps } from '@mui/system';

interface Props {
  name: string;
  label?: string;
  sx?: SxProps;
  multiline?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  noHelperText?: boolean;
  validate?: (value?: any, allValues?: Object, meta?: FieldMetaState<any>) => any;
}

const identity = (value) => value;

export default function RFFTextInput(props: Props) {
  const { name, validate, helperText, noHelperText = false, ...rest } = props;
  return (
    <FinalField name={name} validate={validate} parse={identity}>
      {({ input, meta }) => {
        const hasError = meta.touched && meta.error;
        return <TextareaAutosize {...rest} {...input} fullWidth helperText={noHelperText ? null : hasError || helperText} error={!!hasError} />;
      }}
    </FinalField>
  );
}
