import { observer } from 'mobx-react-lite';
import { Button, CardContent, Box } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import FormGrid from '~/components/@material-extend/form_grid';
import I18n from '~/utils/i18n';
import { PATHS } from '~/utils/constants';
import Switch from '~/components/final_form/switch';
import useLocales from '~/hooks/use_locales';
import Select from '~/components/final_form/select';
import FormContainer from '~/pages/device_edit/containers/form_container';
import { ValueLabelPair } from '~/mst/models/abstract/collection_types';
import { ISyncable } from '~/mst/models/abstract/syncable';
import { IToken } from '~/mst/models/token';
import useMst from '~/hooks/use_mst';

const { VITE_DASHBOARD_URL_ROOT } = process.env;

type PublicDashboardFormType = {
  handleSubmit: VoidFunction;
  model?: ISyncable;
  token?: IToken;
};

const dataAggregationOptions: ValueLabelPair[] = [
  { label: I18n.t('datepicker.ranges.live'), value: null },
  { label: I18n.t('time.number_mins', { n: 15 }), value: 15 },
  { label: I18n.t('time.number_mins', { n: 30 }), value: 30 },
  { label: I18n.t('time.number_mins', { n: 45 }), value: 45 },
  { label: I18n.t('time.one_hour'), value: 60 },
  { label: I18n.t('time.number_hours', { n: 2 }), value: 2 * 60 }
];
const pmUnitsOptions: ValueLabelPair[] = [
  { label: I18n.t('base.labels.auto'), value: null },
  { label: I18n.t(`thiamis.dashboards.pm_${PATHS.PM1}_unit`), value: PATHS.PM1 },
  { label: I18n.t(`thiamis.dashboards.pm_${PATHS.PM25}_unit`), value: PATHS.PM25 },
  { label: I18n.t(`thiamis.dashboards.pm_${PATHS.PM10}_unit`), value: PATHS.PM10 }
];
const tempUnitsOptions: ValueLabelPair[] = [
  { label: I18n.t('base.labels.auto'), value: null },
  { label: I18n.t(`thiamis.dashboards.temp_${PATHS.TEMPERATURE_F}_unit`), value: PATHS.TEMPERATURE_F },
  { label: I18n.t(`thiamis.dashboards.temp_${PATHS.TEMPERATURE_C}_unit`), value: PATHS.TEMPERATURE_C }
];

const vocUnitsOptions: ValueLabelPair[] = [
  { label: I18n.t('base.labels.auto'), value: null },
  { label: I18n.t(`thiamis.dashboards.voc_${PATHS.VOC_ETOH}_unit`), value: PATHS.VOC_ETOH },
  { label: I18n.t(`thiamis.dashboards.voc_${PATHS.VOC_ISOBUTYLENE}_unit`), value: PATHS.VOC_ISOBUTYLENE }
];

function PublicDashboardForm({ handleSubmit, model, token }: PublicDashboardFormType) {
  const { t } = useLocales();
  const { tokens } = useMst();

  return (
    <FormContainer title={t('device_monitor.forms.display')} handleSubmit={handleSubmit} model={model}>
      <CardContent>
        <FormGrid columns="2" sx={{ rowGap: 0, mb: 3 }}>
          <Box>
            <Switch label={t('thiamis.dashboards.enabled')} name="short_url" />
          </Box>
          <Box>
            <Button
              component={Link}
              to={`${VITE_DASHBOARD_URL_ROOT}?token=${token?.external_id_revealed}`}
              variant="contained"
              target="_blank"
              color="primary"
              disabled={tokens.isFetching || Boolean(token) === false || token?.isSyncing}
              size="medium"
            >
              Open Dashboard <OpenInNewIcon size="small" sx={{ ml: 2 }} />
            </Button>
          </Box>
          <Box>
            <Switch label={t('thiamis.dashboards.exposure_time')} name="dashboard_exposure_time" />
          </Box>
        </FormGrid>
        <FormGrid columns="2">
          <Select label={t('thiamis.dashboards.data_aggregation')} name="dashboard_data_aggregation" options={dataAggregationOptions} />
          <Select multiple label={t('thiamis.dashboards.pm_units')} name="dashboard_pm_units" options={pmUnitsOptions} />
          <Select label={t('thiamis.dashboards.temp_units')} name="dashboard_temp_units" options={tempUnitsOptions} />
          <Select label={t('thiamis.dashboards.voc_units')} name="dashboard_voc_units" options={vocUnitsOptions} />
        </FormGrid>
      </CardContent>
    </FormContainer>
  );
}

export default observer(PublicDashboardForm);
