import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { ModelV2 as Model } from '~/mst/models/abstract/model';
import Messages from './messages';

const ThreadModel = t
  .compose(
    t.model({
      name: t.optional(t.string, 'New Thread'),
      messages: t.optional(Messages, [])
    }),
    Model
  )
  .named('Thread');

export interface IThreadModel extends Instance<typeof ThreadModel> {}
export interface IThreadModelSnapshotOut extends SnapshotOut<typeof ThreadModel> {}

export default ThreadModel;
