/* eslint-disable import/prefer-default-export */
import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import pick from 'lodash/pick';
import { IUser } from '~/mst/models/user';
import { getReleaseName } from '~/utils/helpers';

const { VITE_SENTRY_DSN, CI } = process.env;

(() => {
  if (CI) {
    Sentry.init({
      ignoreErrors: ['Network Error', 'AxiosError', 'Request aborted', 'NetworkError', 'Request failed'],
      dsn: VITE_SENTRY_DSN,
      release: getReleaseName(),
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false
        })
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0
    });
  }
})();

export function sentryIdentity(user: IUser) {
  Sentry.setUser(pick(user, 'email', 'external_id'));
}

export function captureException(e: any) {
  Sentry.captureException(e);
}
